exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activatecard-jsx": () => import("./../../../src/pages/activatecard.jsx" /* webpackChunkName: "component---src-pages-activatecard-jsx" */),
  "component---src-pages-business-jsx": () => import("./../../../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-card-jsx": () => import("./../../../src/pages/card.jsx" /* webpackChunkName: "component---src-pages-card-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dapp-jsx": () => import("./../../../src/pages/dapp.jsx" /* webpackChunkName: "component---src-pages-dapp-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-wallet-jsx": () => import("./../../../src/pages/wallet.jsx" /* webpackChunkName: "component---src-pages-wallet-jsx" */)
}

