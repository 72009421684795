import React, { useState, useEffect } from "react";
import mascot from "../assets/hq/mascot.svg";
import { navigate } from "gatsby";
import HamurgerDropdown from "./hamburgerDropDown";
import GetStartedDrawer from "./getStartedDrawer";
import hamburger from "../assets/hq/hamburger.svg";
import GetStartedDropdown from "./getStartedDropdown";
import ConsumerDropDown from "./consumerDropdown";
import { logAnalytics } from "../util";
import { useIsBrowser } from "../hooks/useIsBrowser";
import CardDropdown from "./cardDropdown";

export default function NavBar({ walletRef = null }) {
  const [open, setOpen] = useState(false);
  const [openHam, setOpenHam] = useState(false);
  const isBrowser = useIsBrowser();
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const isRedirect = urlParams.get("redirect");
    if (isRedirect) {
      setShowNavbar(false);
    }
  }, []);

  if (!showNavbar) {
    return <></>;
  }

  return (
    <div className="w-full flex justify-center">
      <nav
        className="lg:bg-transparent p-3 lg:pl-6 lg:pr-3 lg:py-2 rounded-full w-[84%] lg:w-[70%] mx-auto border border-[#EBEDF0] fixed backdrop-blur-[10px] mt-6 cursor-pointer"
        style={{
          zIndex: 999,
          background:
            "linear-gradient(90deg, rgba(255, 229, 136, 0.14) 0%, rgba(188, 102, 190, 0.14) 58.93%, rgba(153, 137, 82, 0.14) 100%)",
        }}
      >
        <div className="container mx-auto flex justify-between items-center text-black text-[15px] font-semibold">
          {/* Logo */}
          <div
            className="flex space-x-1 items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={mascot}
              alt="Cypher Mascot"
              title="Cypher"
              className="w-[31px] h-[38px] lg:w-[40px] lg:h-[49px]"
            />
            <h1 className="font-bold text-[14px] lg:text-[16px]">{"Cypher"}</h1>
          </div>
          <div className="hidden lg:block">
            <ConsumerDropDown walletRef={walletRef} />
          </div>
          <button
            className="hidden lg:block focus:outline-none text-[15px] font-semibold"
            onClick={() => navigate("/business")}
          >
            Business
          </button>
          <div className="hidden lg:block">
            <CardDropdown walletRef={walletRef} />
          </div>
          <button
            className="hidden lg:block focus:outline-none"
            onClick={() => {
              if (isBrowser.current) {
                window.open("https://developer.cypherwallet.io/", "_blank");
                logAnalytics({
                  category: "navbar",
                  action: "click link to developer docs",
                  label: "developers",
                });
              }
            }}
          >
            <div className="flex items-center space-x-1">
              <h2 className="text-[15px] font-semibold">{"Developers"}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
              >
                <path
                  d="M8.56229 2.97291L1.8496 9.68562L0.746582 8.58261L7.45928 1.8699H1.54277V0.309998H10.1222V8.88945H8.56229V2.97291Z"
                  fill="black"
                />
              </svg>
            </div>
          </button>
          <div className="flex space-x-1 items-center">
            <div
              className="lg:hidden"
              onClick={() => setOpenHam((prev) => !prev)}
            >
              <img
                src={hamburger}
                alt="Menu"
                title="Menu"
                width="24"
                height="24"
              />
            </div>
            <div className="hidden lg:block">
              <GetStartedDropdown />
            </div>
            <div
              className="lg:hidden focus:outline-none bg-[#F9D26C] rounded-full p-2 lg:py-2 lg:px-3 flex items-center lg:space-x-1"
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              <h2> Get Started </h2>
            </div>
          </div>
        </div>
      </nav>
      <GetStartedDrawer open={open} setOpen={setOpen} />
      <HamurgerDropdown open={openHam} setOpen={setOpenHam} />
    </div>
  );
}
